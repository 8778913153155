@use 'sass:math';
@use 'sass:string';
@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';

// functions
@function str-remove-whitespace($str) {
  @while (str-index($str, ' ') != null) {
    $index: str-index($str, ' ');
    $str: "#{str-slice($str, 0, $index - 1)}#{str-slice($str, $index + 1)}";
  }
  @return $str;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function get-hsl-values($hsl) {
  $index-1: string.index($hsl, ",");
  $hue: string.slice($hsl, 1, $index-1 - 1);
  $list: (#{$hue});
  $remaining: string.slice($hsl, $index-1 + 1, -1);
  $index-2: string.index($remaining, ",");
  $saturation: str-remove-whitespace(string.slice($remaining, 1, $index-2 - 1));
  $list: list.append($list, #{$saturation});
  $remaining: str-remove-whitespace(string.slice($remaining, $index-2 + 1, -1));
  $list: list.append($list, #{$remaining});
  @return $list;
}

// return color with different opacity value
@function alpha($color, $alpha) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla($color-h, $color-s, $color-l, $alpha);
}

// return color with different lightness value
@function lightness($color, $lightness-multiplier) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightness-multiplier}));
}

// modify color HSLA values
@function adjust-hsla($color, $hue-multiplier: 1, $saturation-multiplier: 1, $lightness-multiplier: 1, $alpha: 1) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla(calc(#{$color-h} * #{$hue-multiplier}), calc(#{$color-s} * #{$saturation-multiplier}), calc(#{$color-l} * #{$lightness-multiplier}), $alpha);
}

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: 'Value for `to-number` should be a number or a string.';
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);
    
    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }
    
    @if $character == '.' {
      $digits: 1; 
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);  
    } @else {
      $digits: $digits * 10;
      $result: $result + math.div(map-get($numbers, $character), $digits);
    }
  }
  
  @return if($minus, -$result, $result);
}

@function to-length($value, $unit) {
  $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);
  
  @if not index(map-keys($units), $unit) {
    $_: 'Invalid unit `#{$unit}`.';
  }
  
  @return $value * map-get($units, $unit);
}

// define HSL color variable
@mixin define-hsl-color($color, $hue, $saturation, $lightness) {
  #{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};#{$color}: hsl(var(#{$color}-h), var(#{$color}-s), var(#{$color}-l));
}

// add a prefix to all the utility classes
$util-prefix: '' !default;

// breakpoints
$breakpoints: (
  'xxs': '26rem',
  'xs': '32rem',
  'sm': '48rem',
  'md': '64rem',
  'lg': '80rem',
  'xl': '90rem'
) !default;

@mixin min-width($breakpoint) {
  @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
}

@mixin max-width($breakpoint) {
  @media not all and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
}

@mixin min-height($breakpoint) {
  @media (min-height: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
}

@mixin max-height($breakpoint) {
  @media not all and (max-height: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
}

// grid
$grid-columns: 12 !default;

// spacing
$spacing: (
  'fluid': (
    '@sm': (
      '4xs': '0.125rem',
      '3xs': '0.25rem',
      '2xs': '0.5rem',
      'xs': '0.75rem',
      'sm': '1rem',
      'md': '1.5rem',
      'lg': '2.25rem',
      'xl': '3.5rem',
      '2xl': '5.75rem',
      '3xl': '9.25rem',
      '4xl': '15rem',
    ),
    '@lg': (
      '4xs': '0.125rem',
      '3xs': '0.25rem',
      '2xs': '0.5rem',
      'xs': '0.75rem',
      'sm': '1rem',
      'md': '1.75rem',
      'lg': '3rem',
      'xl': '5rem',
      '2xl': '8rem',
      '3xl': '12.25rem',
      '4xl': '20rem',
    )
  )
) !default;

// font-family
$font-family: (
  'primary': 'system-ui, sans-serif'
) !default;

// font-size
$font-size: (
  'fluid': (
    '@sm': (
      'xs': '0.6875rem',
      'sm': '0.8125rem',
      'base': '1rem',
      'md': '1.1875rem',
      'lg': '1.4375rem',
      'xl': '1.75rem',
      '1xl': '1.90625rem',
      '2xl': '2.0625rem',
      '3xl': '2.5rem',
      '4xl': '3rem',
    ),
    '@lg': (
      'xs': '0.75rem',
      'sm': '0.9375rem',
      'base': '1.125rem',
      'md': '1.375rem',
      'lg': '1.625rem',
      'xl': '2rem',
      '1xl': '2.25rem',
      '2xl': '2.5rem',
      '3xl': '3rem',
      '4xl': '4rem',
    )
  )
) !default;

// line-height
$line-height: (
  'xs': '1.1',
  'sm': '1.2',
  'md': '1.4',
  'lg': '1.58',
  'xl': '1.72'
) !default;

// colors
$colors: (
  'default': (
    'primary': (
      'darkest': '215, 50%, 25%',
      'darker': '215, 55%, 50%',
      'dark': '215, 65%, 64%',
      'base': '215, 73%, 68%',
      'light': '215, 77%, 74%',
      'lighter': '215, 81%, 80%',
      'lightest': '215, 85%, 94%'
    ),
    'accent': (
      'darkest': '36, 80%, 20%',
      'darker': '36, 80%, 34%',
      'dark': '36, 80%, 43%',
      'below': '36, 80%, 48%',
      'base': '36, 80%, 52%',
      'above': '36, 80%, 57%',
      'light': '36, 80%, 62%',
      'lighter': '36, 80%, 70%',
      'lightest': '36, 80%, 92%'
    ),
    'black': (
      'base': '230, 13%, 9%'
    ),
    'white': (
      'base': '0, 0%, 100%'
    ),
    'warning': (
      'darkest': '5, 59%, 30%',
      'darker': '5, 62%, 48%',
      'dark': '5, 72%, 58%',
      'base': '5, 75%, 66%',
      'light': '5, 78%, 70%',
      'lighter': '5, 82%, 75%',
      'lightest': '5, 88%, 83%'
    ),
    'holiday': (
      'darkest': '136, 39%, 15%',
      'darker': '136, 42%, 25%',
      'dark': '136, 52%, 35%',
      'base': '136, 55%, 49%',
      'light': '136, 58%, 55%',
      'lighter': '136, 62%, 62%',
      'lightest': '136, 90%, 85%'
    ),
    'success': (
      'darkest': '36, 68%, 26%',
      'darker': '36, 68%, 45%',
      'dark': '36, 68%, 52%',
      'below': '36, 90%, 58%',
      'base': '36, 90%, 64%',
      'above': '36, 90%, 69%',
      'light': '36, 90%, 75%',
      'lighter': '36, 90%, 83%',
      'lightest': '36, 90%, 90%'
    ),
    'error': (
      'darkest': '0, 50%, 25%',
      'darker': '0, 55%, 50%',
      'dark': '0, 65%, 64%',
      'base': '0, 73%, 68%',
      'light': '0, 77%, 74%',
      'lighter': '0, 81%, 80%',
      'lightest': '0, 85%, 94%'
    ),
    'bg': (
      'darkest': '206, 20%, 8%',
      'darker': '206, 20%, 10%',
      'dark': '206, 20%, 13%',
      'base': '206, 30%, 20%',
      'light': '207, 40%, 26%',
      'lighter': '206, 50%, 28%',
    ),
    'article': (
      'base': '209, 30%, 15%',
      'light': '209, 30%, 18%',
    ),
    'contrast': (
      'lowest': '215, 12%, 5%',
      'lower': '215, 12%, 10%',
      'between_lower_low': '215, 12%, 16%',
      'low': '215, 12%, 23%',
      'below': '215, 12%, 29%',
      'medium': '215, 12%, 45%',
      'above': '215, 15%, 50%',
      'high': '215, 15%, 77%',
      'between_higher_high': '215, 17.5%, 86%',
      'higher': '215, 20%, 95%',
      'highest': '215, 8%, 98%'
    ),
    'rosette': (
      'darker': '206, 77%, 37%',
      'dark': '206, 80%, 48%',
      'base': '208, 83%, 59%',
      'light': '212, 85%, 70%',
    ),
    'button': (
      'darker': '210, 20%, 25%',
      'dark': '210, 20%, 37%',
      'below': '195, 20%, 44%',
      'base': '195, 20%, 50%',
      'above': '195, 23%, 60%',
      'light': '200, 25%, 70%',
      'lighter': '200, 28%, 80%'
    )
  ),
) !default;

$gradients: () !default;

// aspect-ratio
$aspect-ratio: (16 9, 3 2, 4 3, 5 4, 1 1, 4 5, 3 4, 2 3, 9 16) !default;

// media-wrapper
$media-wrapper: (16 9, 3 2, 4 3, 1 1) !default;

// width
$width: (
  '4xs': '0.25rem',
  '3xs': '0.5rem',
  '2xs': '0.75rem',
  'xs': '1rem',
  'sm': '1.5rem',
  'md': '2rem',
  'lg': '3rem',
  'xl': '4rem',
  '2xl': '6rem',
  '3xl': '8rem',
  '4xl': '16rem',
  0: '0',
  10\%: '10%',
  20\%: '20%',
  25\%: '25%',
  30\%: '30%',
  33\%: '33%',
  40\%: '40%',
  50\%: '50%',
  60\%: '60%',
  70\%: '70%',
  75\%: '75%',
  80\%: '80%',
  90\%: '90%',
  100\%: '100%'
) !default;

// height
$height: (
  '4xs': '0.25rem',
  '3xs': '0.5rem',
  '2xs': '0.75rem',
  'xs': '1rem',
  'sm': '1.5rem',
  'md': '2rem',
  'lg': '3rem',
  'xl': '4rem',
  '2xl': '6rem',
  '3xl': '8rem',
  '4xl': '16rem',
  0: '0',
  10\%: '10%',
  20\%: '20%',
  25\%: '25%',
  30\%: '30%',
  33\%: '33%',
  40\%: '40%',
  50\%: '50%',
  60\%: '60%',
  70\%: '70%',
  75\%: '75%',
  80\%: '80%',
  90\%: '90%',
  100\%: '100%'
) !default;

// max-width
$max-width: (
  '3xs': '20rem',
  '2xs': '26rem',
  'xs': '32rem',
  'sm': '48rem',
  'md': '64rem',
  'lg': '80rem',
  'xl': '90rem'
) !default;

$container-margin-x: var(--space-md) !default;

// box-shadow
$box-shadow: (
  'ring': '0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.5)',
  'xs': '0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2), 0 1px 3px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2)',
  'sm': '0 1px 2px rgba(0, 0, 0, 0.2)',
  'md': '0 2px 4px rgba(0, 0, 0, 0.2)',
  'lg': '0 4px 8px rgba(0, 0, 0, 0.2)',
  'xl': '0 8px 16px rgba(0, 0, 0, 0.2)',
) !default;

// inner-glow
$inner-glow: (
  'glow': 'inset 0 0 0.5px 1px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075)',
  'glow-top': 'inset 0 1px 0.5px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075)'
) !default;

// border-radius
$border-radius: (
  'sm': '0.1875em',
  'md': '0.375em',
  'lg': '0.75em'
) !default;

// z-index
$z-index: (
  'header': '3',
  'popover': '5',
  'fixed-element': '10',
  'overlay': '15'
) !default;

// reset user agent style
@mixin reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

// accessibility → hide
@mixin srHide {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

// accessibility → show
@mixin srShow {
  position: static;
  clip: auto;
  clip-path: none;
}

// edit font rendering
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// triangle
@mixin triangle ($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if( $direction == left ) {
    border-right-color: $color;
  } @else if( $direction == right ) {
    border-left-color: $color;
  } @else if( $direction == down ) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

// breakpoint (deprecated → use the min-width mixin)
@mixin breakpoint($breakpoint, $logic: false) {
  @if( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}

// define HSL color variables (deprecated → use the define-hsl-color mixin)
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}

// modify color HSLA values (deprecated → use the adjust-hsla mixin)
@function adjustHSLA($color, $hue-multiplier: 1, $saturation-multiplier: 1, $lightness-multiplier: 1, $alpha: 1) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla(calc(#{$color-h} * #{$hue-multiplier}), calc(#{$color-s} * #{$saturation-multiplier}), calc(#{$color-l} * #{$lightness-multiplier}), $alpha);
}

// line-height crop (deprecated)
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}