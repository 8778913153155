
.banner_and_analysis {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    width: 100%;
}

.analysis {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--space-xs);
    flex-wrap: wrap;
    padding: 0 var(--space-md);
    width: 100%;

    &.reverse {
        flex-direction: row-reverse;
    }

    .player {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: var(--color-bg-dark);
        border-radius: var(--space-xs);
        overflow: hidden;
        padding: var(--space-2xs);
        padding-top: var(--space-3xs);

        .name_and_help {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            font-size: var(--text-base);

            .name {
                max-width: 85%;
                overflow: hidden;
                margin-right: auto;
            }
            .help {
                margin-top: var(--space-3xs);
            }
        }

        .accuracy_container {
            margin-bottom: var(--space-xs);
            &.with_review {
                margin-top: var(--space-xs);
                margin-bottom: var(--space-sm);
            }
        }
        .review_game_link {
            font-size: var(--text-base);
            margin: 0;
        }

        .accuracy, .rating {
            font-weight: bold;
            font-size: var(--text-base);
            margin: 0;

            &.above_accuracy {
                //color: var(--color-error-dark);
                color: hsl(125, 58%, 52%);
            }
            &.below_accuracy {
                color: var(--color-error-dark);
            }

            .the_rating.your_player {
                color: var(--color-accent-light);
            }

            .the_rating.not_your_player, .the_rating.complete, .change {
                &.increase {
                    color: var(--color-primary);
                }
                &.decrease {
                    color: var(--color-error);
                }
            }
        }

        .ruleset {
            font-size: var(--text-base);
            margin: var(--space-xs) 0 0;
            margin-top: 0;
        }
    }
}
