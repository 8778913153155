
.container {
    display: flex;
    flex-direction: column;
    padding: var(--space-2xs);
    padding-bottom: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .move_container {
        padding-bottom: var(--space-xs);
    }
}
