@use "@/scss/config" as *;


.board_container {
    position: relative;

    &.portrait {
        width: 100%;
        height: 100%;
        max-width: 46vh;
    }
    &.landscape {
        height: 100%;
        min-width: 46vh;
    }

    &.horizontal, &.vertical {
        flex-grow: 1;
    }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .move_highlight {
        position: absolute;
        width: 32px;
        height: 32px;
        max-width: 2em;
        object-fit: contain;
        border-radius: 50%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.25);
        padding: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .behind_board_image {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        transform: translateX(-50%);
    }
}
