
.snake {
    margin: 0 0 var(--space-xs);

    .graph {
        height: 6rem;
        svg {
            border-radius: 0;
        }
    }
}
