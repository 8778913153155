@use "@/scss/config" as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    .game_status {
        display: flex;
        flex-direction: column;
        align-items: center;
        pointer-events: auto;

        font-size: var(--text-sm);
        font-weight: bold;
        color: var(--color-contrast-higher);
        background-color: var(--color-contrast-low);

        padding: var(--space-3xs) var(--space-xs);
        border-bottom-left-radius: var(--space-xs);
        border-bottom-right-radius: var(--space-xs);

        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);

        @include min-width(sm) {
            font-size: var(--text-base);
            padding: var(--space-2xs) var(--space-sm);
        }

        .music_button {
            vertical-align: middle;

            svg {
                display: inline;
                height: 1.25em;
                margin-right: 0.4em;
                fill: currentColor;
            }

            svg, span {
                vertical-align: middle;
            }
        }
    }
}

.music_controls {
    width: 340px;
    min-width: 340px;
    max-width: 340px;

    .top_row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        gap: var(--space-xs);

        .title_and_author {
            flex-grow: 1;
        }
        .hide_button {
            color: var(--color-contrast-medium);
            margin-left: auto;
            cursor: pointer;
        }
    }

    p {
        margin: 0;
        &.title {
            color: var(--color-contrast-higher);
        }
        &.author {
            color: var(--color-contrast-high);
        }
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: var(--space-xs);
        gap: var(--space-xs);

        button {
            cursor: pointer;

            &:hover svg {
                transform: scale(1.1);
            }
        }

        &.playing {
            .play {
                fill: var(--color-contrast-medium);
            }
            .pause {
                fill: var(--color-contrast-higher);
            }
        }
        &.paused {
            .play {
                fill: var(--color-contrast-higher);
            }
            .pause {
                fill: var(--color-contrast-medium);
            }
        }

        svg {
            display: inline;
            height: 2.5em;
            fill: var(--color-contrast-higher);

            &.settings {
                padding: 0.25em;
            }
        }
    }
}
