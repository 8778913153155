
/**
 * Modified from https://toughengineer.github.io/demo/slider-styler
 */
.slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input[type=range] {
        height: 1.7em;
        box-shadow: none;
        -webkit-appearance: none;
        cursor: pointer;

        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));

        --slider-progress-col: hsl(210, 100%, 49%);
        --slider-progress-col-hovered: hsl(210, 100%, 45%);
        --slider-background-col: #efefef;
        --slider-background-col-hovered: #e5e5e5;
    }

    input[type=range]:focus {
        outline: none;
    }

    /*webkit*/
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 1.5em;
        height: 1.5em;
        border-radius: 0.75em;
        background: var(--slider-progress-col);
        box-shadow: 0 0 2px black;
        margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - 1.5em * 0.5);
    }

    input[type=range]::-webkit-slider-runnable-track {
        height: 1em;
        border-radius: 0.5em;
        background: var(--slider-background-col);
        box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.3);
    }

    input[type=range]:hover::-webkit-slider-thumb {
        background: var(--slider-progress-col-hovered);
    }

    input[type=range]:hover::-webkit-slider-runnable-track {
        background: var(--slider-background-col-hovered);
    }

    input[type=range]:active::-webkit-slider-thumb {
        background: var(--slider-progress-col);
    }

    input[type=range]:active::-webkit-slider-runnable-track {
        background: var(--slider-background-col);
    }

    input[type=range].slider_progress::-webkit-slider-runnable-track {
        background: linear-gradient(var(--slider-progress-col),var(--slider-progress-col)) 0/var(--sx) 100% no-repeat, var(--slider-background-col);
    }

    input[type=range].slider_progress:hover::-webkit-slider-runnable-track {
        background: linear-gradient(var(--slider-progress-col-hovered),var(--slider-progress-col-hovered)) 0/var(--sx) 100% no-repeat, var(--slider-background-col-hovered);
    }

    input[type=range].slider_progress:active::-webkit-slider-runnable-track {
        background: linear-gradient(var(--slider-progress-col),var(--slider-progress-col)) 0/var(--sx) 100% no-repeat, var(--slider-background-col);
    }

    /*mozilla*/
    input[type=range]::-moz-range-thumb {
        width: 1.5em;
        height: 1.5em;
        border-radius: 0.75em;
        background: var(--slider-progress-col);
        box-shadow: 0 0 2px black;
    }

    input[type=range]::-moz-range-track {
        height: max(calc(1em - 1px - 1px),0px);
        border-radius: 0.5em;
        background: var(--slider-background-col);
        box-shadow: none;
    }

    input[type=range]::-moz-range-thumb:hover {
        background: var(--slider-progress-col-hovered);
    }

    input[type=range]:hover::-moz-range-track {
        background: var(--slider-background-col-hovered);
    }

    input[type=range]::-moz-range-thumb:active {
        background: var(--slider-progress-col);
    }

    input[type=range]:active::-moz-range-track {
        background: var(--slider-background-col);
    }

    input[type=range].slider_progress::-moz-range-track {
        background: linear-gradient(var(--slider-progress-col),var(--slider-progress-col)) 0/var(--sx) 100% no-repeat, var(--slider-background-col);
    }

    input[type=range].slider_progress:hover::-moz-range-track {
        background: linear-gradient(var(--slider-progress-col-hovered),var(--slider-progress-col-hovered)) 0/var(--sx) 100% no-repeat, var(--slider-background-col-hovered);
    }

    input[type=range].slider_progress:active::-moz-range-track {
        background: linear-gradient(var(--slider-progress-col),var(--slider-progress-col)) 0/var(--sx) 100% no-repeat, var(--slider-background-col);
    }

    /*ms*/
    input[type=range]::-ms-fill-upper {
        background: transparent;
    }

    input[type=range]::-ms-fill-lower {
        background: transparent;
    }

    input[type=range]::-ms-thumb {
        width: 1.5em;
        height: 1.5em;
        border-radius: 0.75em;
        background: var(--slider-progress-col);
        border: none;
        box-shadow: 0 0 2px black;
        margin-top: 0;
        box-sizing: border-box;
    }

    input[type=range]::-ms-track {
        height: 1em;
        border-radius: 0.5em;
        background: var(--slider-background-col);
        box-shadow: none;
        box-sizing: border-box;
    }

    input[type=range]::-ms-thumb:hover {
        background: var(--slider-progress-col-hovered);
    }

    input[type=range]:hover::-ms-track {
        background: var(--slider-background-col-hovered);
    }

    input[type=range]::-ms-thumb:active {
        background: var(--slider-progress-col);
    }

    input[type=range]:active::-ms-track {
        background: var(--slider-background-col);
    }

    input[type=range].slider_progress::-ms-fill-lower {
        height: max(calc(1em - 1px - 1px),0px);
        border-radius: 0.5em 0 0 0.5em;
        margin: -1px 0 -1px -1px;
        background: var(--slider-progress-col);
    }

    input[type=range].slider_progress:hover::-ms-fill-lower {
        background: var(--slider-progress-col-hovered);
    }

    input[type=range].slider_progress:active::-ms-fill-lower {
        background: var(--slider-progress-col);
    }
}
