
.setup_container, .loading_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loading_container {
  article {
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;

    section {
      border-radius: var(--space-sm);
      background-color: rgba(0, 0, 0, 0.2);
      padding: var(--space-sm);
      margin-bottom: var(--space-lg);
      min-height: var(--space-3xl);
    }
  }
}

.game_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
