
.preferences {
    padding-top: 0;
}

.name_colour_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    .membur_icon {
        font-size: var(--text-base);
        margin-left: var(--space-2xs);
        margin-top: var(--space-sm);
    }
}

.disable_music_permanently {
    display: inline;
    color: var(--color-primary);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
