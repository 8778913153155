@use "@/scss/config" as *;

.footer {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: auto 0 0;
  padding: var(--space-lg);
  padding-bottom: var(--space-3xl);
  z-index: 1;
  background-color: var(--color-bg-darker);

  @include min-width(sm) {
    padding-bottom: var(--space-xl);
  }

  font-size: 1.5em;
  text-align: left;
}

.footer_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--space-xl);
  column-gap: var(--space-xl);
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @include min-width(sm) {
    flex-direction: row;
  }

  .footer_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    margin-left: auto;
    margin-right: auto;

    @include min-width(sm) {
      align-items: flex-end;
    }

    .logo {
      max-width: 10em;
      margin: 0;
    }
  }

  .footer_links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: var(--space-xl);
    flex-grow: 1;

    .link_column {
      width: 100%;
      max-width: 200px;

      &, .link_column_section {
        display: flex;
        flex-direction: column;
        row-gap: var(--space-xs);
      }

      .link_column_section {
        margin-bottom: var(--space-md);
      }

      span, a, button {
        display: inline-block;
        text-align: left;
      }
    }
  }

  .footer_social_icons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 1.2em;
    row-gap: 1.2em;
    max-width: 12em;

    @include min-width(sm) {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .social_icon {
      display: inline-block;
      line-height: 0;

      &, img {
        width: 2.6em;
      }
    }
  }

  .link_column_title,
  .link,
  .copyright {
    font-family: sans-serif;
    vertical-align: middle;
  }
  .link_column_title {
    font-size: var(--text-md);
    font-weight: bold;
    color: var(--color-contrast-higher);
    margin: 0;
  }
  .link {
    font-size: var(--text-md);
    color: var(--color-contrast-above);
  }
  .copyright {
    font-size: var(--text-base);
    color: var(--color-contrast-medium);
    margin-top: var(--space-sm);
    margin-bottom: var(--space-md);
  }
}
