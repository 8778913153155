
.menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--color-bg-darker);
    font-size: var(--text-lg);

    a {
        width: 100%;
        color: var(--color-contrast-higher);
        font-size: var(--text-md);
        font-weight: bold;
        padding: var(--space-sm) var(--space-md);
        padding-left: var(--space-sm);
        background-color: var(--color-bg);

        &:hover {
            color: var(--color-contrast-higher);
            background-color: var(--color-bg-darkest);
            text-decoration: none;
        }

        &.logout {
            color: var(--color-contrast-high);
        }

        &.memburship {
            color: var(--color-accent-darkest);
            background-color: var(--color-accent);
            background: linear-gradient(180deg, var(--color-accent-above) 0%, var(--color-accent) 100%);
            img {
                transform: scale(1.25) translateX(-0.1em);
            }

            &:hover {
                color: var(--color-accent-lightest);
                background: linear-gradient(180deg, var(--color-accent) 0%, var(--color-accent) 100%);
            }
        }

        img {
            display: inline-block;
            width: 100%;
            max-width: 1.5em;
            max-height: 1.5em;
            margin-right: var(--space-2xs);
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
        }
    }
}
