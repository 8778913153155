@use "@/scss/config" as *;

.menu_container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-size: var(--text-base);
    pointer-events: none;

    display: flex;
    &.landscape {
        align-items: center;
    }
    &.portrait {
        align-items: flex-end;
    }

    &.landscape .menu_button {
        top: 0;
    }
    &.portrait .menu_button {
        bottom: 0;
    }

    &.landscape {
        padding-top: 6em;
    }
    &.portrait {
        padding-bottom: 7em;
    }

    .status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 98;
    }

    .menu_button {
        position: absolute;
        z-index: 100;
        width: 4em;
        height: 4em;
        padding-bottom: 0.4em;
        margin: 1.2em;
        pointer-events: all;

        opacity: 0.65;
        border-radius: 1em;
        cursor: pointer;
        transition: 0.1s transform ease-in-out;

        &:hover {
            transform: scale(1.075);
        }
        &:active {
            transform: none;
        }

        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }

        background-color: rgb(15, 15, 15);
        &.active {
            background-color: rgb(255, 255, 255);
            opacity: 0.85;
            svg {
                fill: var(--color-contrast-lower);
            }
        }

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            fill: var(--color-contrast-high);
        }
        svg.burger_button {
            width: 62%;
            height: 62%;
        }
        svg.close_button {
            width: 76%;
            height: 76%;
        }
    }
    &.landscape .menu_button {
        opacity: 0.75;
        &.active {
            opacity: 0.85;
        }
    }
    &.portrait .menu_button {
        width: 3.5em;
        height: 3.5em;
    }

    &.portrait .menu_options {
        width: 90vw;
        max-width: 90vw;
    }

    .menu {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: all;

        .menu_scroll_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            padding: var(--space-xs);
            min-height: 100%;
        }

        .menu_options {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: min(28em, 100%);
            max-width: 28em;
            width: 100%;
            gap: var(--space-md);

            @include min-width(sm) {
                gap: var(--space-sm);
            }
        }

        .menu_option {
            font-size: var(--text-lg);
            font-weight: bold;
            color: var(--color-button-darker);
            width: 100%;
            max-width: 14.5em;
            text-align: center;
            cursor: pointer;
            user-select: none;
            text-decoration: none;

            background-color: var(--color-button-light);
            border-bottom: var(--space-3xs) var(--color-button) solid;
            border-radius: var(--space-xs);
            padding: 0.5em var(--space-md);

            .menu_option_icon {
                vertical-align: middle;
                margin-right: 0.25em;
                img {
                    width: 100%;
                    max-width: 1.25em;
                    max-height: 1.25em;
                }
            }

            &.small {
                font-size: var(--text-md);
            }

            &:hover {
                background-color: var(--color-button-lighter);
                text-decoration: none;
            }
            &:active {
                background-color: var(--color-button-light);
            }

            &.disabled_option {
                color: var(--color-contrast-low);
                background-color: var(--color-contrast-medium);
                border-bottom-color: var(--color-contrast-low);
            }

            &.leave_game_option {
                color: var(--color-warning-darkest);
                background-color: var(--color-warning);
                border-bottom-color: var(--color-warning-dark);

                &:hover {
                    background-color: var(--color-warning-light);
                }
                &:active {
                    background-color: var(--color-warning);
                }
            }

            &.play_again_option {
                animation: breathe 4s ease-in-out infinite;

                &.rematch_requested {
                    animation: breathe 2s ease-in-out infinite;
                    color: var(--color-contrast-higher);
                    background-color: var(--color-holiday);
                    border-bottom-color: var(--color-holiday-dark);
                    &:hover {
                        background-color: var(--color-holiday-light);
                    }
                    &:active {
                        background-color: var(--color-holiday);
                    }
                }

                @media (prefers-reduced-motion) {
                    animation: none;
                }
                @keyframes breathe {
                    0% {
                        transform: scale(1.0);
                    }
                    50% {
                        transform: scale(1.0);
                    }
                    75% {
                        transform: scale(1.05);
                    }
                    100% {
                        transform: scale(1.0);
                    }
                }
            }
            &.holiday_option {
                color: var(--color-holiday-darkest);
                background-color: var(--color-holiday);
                border-bottom-color: var(--color-holiday-dark);

                &:hover {
                    background-color: var(--color-holiday-light);
                }
                &:active {
                    background-color: var(--color-holiday);
                }
            }
        }
    }
    &.portrait {
        .menu {
            padding-bottom: 4.5em;
            font-size: var(--text-md);
            .menu_scroll_box {
                justify-content: flex-end;
            }
        }
    }
}

.icon {
    display: inline-block;
    width: auto;
    height: 100%;
    max-width: 2em;
    max-height: 1.5em;
    object-fit: contain;
    vertical-align: middle;
    margin-right: var(--space-2xs);
}

.menu_card {
    padding: var(--space-xs) var(--space-md);
    .menu_card_banner {
        margin: 0 auto;
    }
}

.dark_overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 98;
}
