
.debug_info {
    p.debug_desc {
        white-space: pre-wrap;
        font-size: var(--text-sm);
        margin: 0;
        padding: 0 var(--space-3xs);
    }

    select.info_selector {
        color: var(--color-contrast-lower);
        background-color: var(--color-contrast-higher);
        border: 1px solid var(--color-contrast-medium);
        border-radius: var(--space-2xs);
        font-size: var(--text-sm);
        padding: var(--space-3xs) var(--space-2xs);
        width: 100%;
        max-width: 200px;
        cursor: pointer;
        margin-bottom: var(--space-xs);

        &:focus {
            border-color: var(--color-contrast-lower);
            outline: none;
        }
    }
}
