
.menu {
    .card {
        width: 100%;
        padding-top: var(--space-md);
        .back {
            font-size: var(--text-md);
            color: var(--color-contrast-high)
        }
    }
}
