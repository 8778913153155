@use "@/scss/config" as *;

.button {
    overflow: hidden;
    background-color: var(--color-contrast-high);
    border-radius: 100%;
    padding: 0.15em;
    margin-bottom: 0.1em;
    margin-left: var(--space-2xs);

    svg {
        fill: var(--color-contrast-low);
        width: 0.85em;
        height: 0.85em;
    }
}
