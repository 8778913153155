
.panda {
    position: absolute;
    top: -2.5em;
    right: 0.2em;
    pointer-events: auto;

    &, img {
        width: 5em;
        height: auto;
    }
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));

    &:hover {
        animation: quick_wiggle 1s ease-out;
        @media (prefers-reduced-motion) {
            animation: none;
        }
    }

    img {
        transition: 0.4s transform ease-in-out;
        transform: translate(0, 0) rotate(5deg);
    }
    &:hover img {
        cursor: pointer;
        transform: translate(0, -0.5em) rotate(5deg);
    }
}

@keyframes quick_wiggle {
    40% {
        transform: rotate(0);
    }
    55% {
        transform: rotate(-1.5deg) translate(-0.09em, 0.06em);
    }
    70% {
        transform: rotate(1.5deg) translate(0.09em, -0.06em);
    }
    85% {
        transform: rotate(-1.5deg) translate(-0.09em, 0.06em);
    }
    100% {
        transform: rotate(0);
    }
}
