
.bg {
    fill: var(--color-bg-darker);
}

.label {
    font-size: var(--text-lg);
    font-weight: bold;
    vertical-align: middle;
}

.tooltip {
    background-color: white;
    z-index: 999;
}
