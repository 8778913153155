
.banner_container {
    position: relative;
    margin-top: 3em;
    width: 100%;
}

.banner_container.holiday .win_menu {
    color: var(--color-holiday-darkest);
    background-color: var(--color-holiday);
    background-image: linear-gradient(
                    180deg,
                    var(--color-holiday) 0%,
                    var(--color-holiday-below) 100%,
    );
    border-bottom-color: var(--color-holiday-darker);

    .winner .winner_shine {
        color: var(--color-holiday-lightest);
    }
}

.win_menu {
    position: relative;
    color: var(--color-success-darkest);
    background-color: var(--color-success);
    background-image: linear-gradient(
                    180deg,
                    var(--color-success) 0%,
                    var(--color-success-below) 100%,
    );
    border-bottom: var(--space-3xs) var(--color-success-darker) solid;
    border-radius: var(--space-sm);
    padding: var(--space-sm);
    text-align: center;
    pointer-events: auto;

    div.winner {
        font-size: var(--text-3xl);
        font-weight: bold;
        div {
            position: relative;
            display: inline-block;

            &.winner_shine {
                animation: pulse 4s ease-out infinite;
                @media (prefers-reduced-motion) {
                    animation: none;
                }
            }

            .winner_shine {
                position: absolute;
                left: 0;
                top: 0;
                color: var(--color-success-lightest);
                animation: shine 4s ease-out infinite;
                @media (prefers-reduced-motion) {
                    animation: none;
                    display: none;
                }
            }
        }
    }
    div.win_text {
        font-size: var(--text-xl);
    }

    .crown {
        position: absolute;
        top: -3.25em;
        left: -2.2em;
        &, img {
            width: 6em;
            height: auto;
        }
        filter: brightness(105%) drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
        animation: wiggle 8s linear infinite;
        @media (prefers-reduced-motion) {
            animation: none;
        }
    }

    .left_candy_cane {
        position: absolute;
        top: 50%;
        left: 0;
        animation-delay: 2s;
        transform: translate(-10%, -52.5%) rotate(-20deg);

        &, img {
            height: 12em;
            width: auto;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1.0);
    }
    15% {
        transform: scale(1.0);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.0);
    }
}

@keyframes shine {
    0% {
        clip-path: polygon(-150% -100%, -200% 100%, -185% 100%, -135% -100%);
    }
    50% {
        clip-path: polygon(150% -100%, 100% 100%, 115% 100%, 165% -100%);
    }
    100% {
        clip-path: polygon(150% -100%, 100% 100%, 115% 100%, 165% -100%);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0);
    }
    87% {
        transform: rotate(0);
    }
    88.5% {
        transform: rotate(-1.5deg) translate(-0.09em, 0.06em);
    }
    90% {
        transform: rotate(1.5deg) translate(0.09em, -0.06em);
    }
    91.5% {
        transform: rotate(-1.5deg) translate(-0.09em, 0.06em);
    }
    93% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}
