
.container {
    padding: var(--space-2xs);
    border-radius: var(--space-xs);
    background-color: rgba(30, 30, 30, 0.4);
    cursor: pointer;

    &.light_turn {
        background-color: rgba(120, 120, 110, 0.4);
    }

    &.selected {
        background-color: var(--color-contrast-lower);
        cursor: auto;
    }

    &.membur_prompt, &.end_card {
        background-color: var(--color-bg);
        cursor: auto;
    }

    .summary {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--space-3xs);
    }

    &.selected .summary {
        margin-bottom: var(--space-2xs);
    }
    &.selected.no_details .summary {
        margin-bottom: 0;
    }

    .small_icon {
        display: inline;
        width: 1em;
        height: 1em;
        min-width: 1em;
        transform: scale(1.2) translate(0, 0.1em);
    }

    .summary {
        .icon {
            height: 1.6em;
            width: 1.6em;
            min-width: 1.6em;
            flex: 0;
        }

        p {
            font-size: var(--text-sm);
            margin: 0;
        }

        p.move_number {
            color: var(--color-contrast-high);
        }

        p.summary_desc {
            flex: 1;
        }

        p.win_percentage {
            display: block;
            padding: 0 var(--space-3xs);
            border-radius: var(--space-3xs);
            flex: 0;

            background-color: var(--color-contrast-lower);
            color: var(--color-contrast-higher);

            &.light_winning {
                background-color: var(--color-contrast-high);
                color: var(--color-contrast-low);
            }
        }
    }

    &.selected .icon {
        height: 2em;
        width: 2em;
        min-width: 2em;
    }

    &.selected p.summary_desc {
        font-weight: bold;
    }

    &.selected p.win_percentage {
        background-color: var(--color-contrast-low);
    }

    p.roll_desc, p.move_desc, p.best_move {
        font-size: var(--text-sm);
        margin: 0;
        padding: 0 var(--space-3xs);
    }

    p.move_desc, p.best_move {
        margin-top: var(--space-2xs);
    }

    .best_move_delta {
        padding: 0 var(--space-3xs);
        border-radius: var(--space-3xs);
        margin-left: var(--space-2xs);
        color: var(--color-primary-lighter);
        background-color: var(--color-contrast-low);
    }

    .button {
        width: 100%;
        margin-top: var(--space-xs);
    }
}

.debug {
    button {
        font-size: var(--text-sm);
        color: var(--color-primary);
        cursor: pointer;
        margin-top: var(--space-xs);
        margin-bottom: var(--space-2xs);

        &:hover {
            text-decoration: underline;
            color: var(--color-primary-dark);
        }
    }
}
