
.fireworks_canvas {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
