
.player_name_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-xs);

    .icon {
        flex-grow: 0;
        width: 4em;
        height: 4em;
        object-fit: contain;
    }
}

.player_type {
    color: var(--color-contrast-medium);
    font-size: var(--text-md);
    margin: 0;
}
