
div.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: var(--space-sm) 0;

    label, span {
        font-size: var(--text-base);
        font-weight: normal;
        cursor: pointer;
    }

    label {
        margin: 0 var(--space-xs) 0 0;
    }
    span {
        margin: 0 0 0 var(--space-xs);
    }

    .control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        input {
            flex-grow: 1;
            max-width: 12em;
        }
        span {
            flex-grow: 0;
        }
    }
}
