
.intro_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.intro_menu {
    position: relative;
    width: 100%;
    max-width: 30rem;
    background-color: var(--color-contrast-low);
    background-image: linear-gradient(
                    180deg,
                    var(--color-contrast-low) 0%,
                    var(--color-contrast-between_lower_low) 100%,
    );
    border-bottom: var(--space-3xs) var(--color-contrast-lower) solid;
    border-radius: var(--space-sm);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.5);
    padding: var(--space-md) var(--space-sm);
    padding-bottom: var(--space-xs);
    text-align: center;
    pointer-events: auto;

    h1 {
        font-size: var(--text-2xl);
        color: var(--color-contrast-higher);
        margin-top: 0;
        span {
            margin-right: 1.4em;
        }
    }

    h2 {
        font-size: var(--text-lg);
        color: var(--color-contrast-higher);
        margin-top: 0;
        margin-bottom: var(--space-sm);
        text-align: left;
    }

    p {
        text-align: left;
        font-size: var(--text-md);
        color: var(--color-contrast-high);
        padding: var(--space-xs);
        margin-bottom: 0;
    }

    figure {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -105%);
        width: 100%;
        max-width: min(16em, 70vw);
        margin: 0;
        pointer-events: auto;
    }
}

.start_summary {
    display: flex;
    flex-direction: row;
    gap: var(--space-xs);

    text-align: left;
    margin-bottom: var(--space-xs);

    .panda_bot {
        flex: 0;
        width: 3.5em;
        height: 2em;
        min-width: 3.5em;
        min-height: 2em;
        object-fit: contain;
    }

    p {
        flex: 1;
        font-size: var(--text-sm);
        padding: var(--space-3xs) var(--space-xs);
        border-radius: var(--space-xs);
        border-top-right-radius: 0;
    }
}

.play_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: var(--space-sm) 0;
    gap: var(--space-sm);
}

p.quote {
    font-size: var(--text-md);
    margin: 0;
    white-space: pre-wrap;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.3);
}

.icon {
    display: inline-block;
    max-width: 1.8em;
    max-height: 1.8em;
    margin-right: 0.4em;
    vertical-align: middle;
}
