
.summary {
    margin-bottom: var(--space-xs);

    .header_section {
        padding: var(--space-xs);
        padding-bottom: 0;

        .snake_graph_figure {
            margin-bottom: 0;
        }
    }

    .start_review_section {
        padding: var(--space-xs);
        padding-top: var(--space-sm);
        border-radius: var(--space-sm);
        background-color: rgba(0, 0, 0, 0.3);
    }

    &, .col {
        display: flex;
        flex-direction: column;
        gap: var(--space-3xs);

        &.col_fill_space {
            flex: 1;
        }
    }

    .row {
        display: flex;
        flex-direction: row;

        & > p, & > div {
            display: block;
            flex: 1;
            text-align: center;
        }
    }

    .col > p {
        display: block;
        height: 1.6em;
        min-height: 1.6em;
        max-height: 1.6em;
        overflow: hidden;
    }

    .start_summary {
        display: flex;
        flex-direction: row;
        gap: var(--space-xs);

        text-align: left;
        margin-bottom: var(--space-xs);

        .panda_bot {
            flex: 0;
            width: 3.5em;
            height: 2em;
            min-width: 3.5em;
            min-height: 2em;
            object-fit: contain;
        }

        p {
            flex: 1;
            font-size: var(--text-sm);
            padding: var(--space-3xs) var(--space-xs);
            border-radius: var(--space-xs);
            border-top-right-radius: 0;
        }
    }

    .stats {
        display: inline-block;
        width: 100%;
        max-width: 13em;
        padding: var(--space-3xs) var(--space-sm);
        border-radius: var(--space-2xs);

        color: var(--color-contrast-high);
        background-color: var(--color-contrast-lower);

        p {
            font-size: var(--text-sm);

            &.luck {
                font-weight: bold;
            }

            &.rating_change {
                &.increase {
                    color: var(--color-primary);
                }
                &.decrease {
                    color: var(--color-error);
                }
            }
        }

        &.light {
            color: var(--color-contrast-low);
            background-color: var(--color-contrast-between_higher_high);

            p.rating_change {
                &.increase {
                    color: var(--color-primary-darker);
                }
                &.decrease {
                    color: var(--color-error-darker);
                }
            }
        }
    }

    p {
        font-size: var(--text-base);
        text-align: center;
        margin: 0;

        &.quote, &.debug_overview {
            white-space: pre-wrap;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.3);
        }

        &.debug_overview {
            font-size: var(--text-sm);
            padding: var(--space-3xs) var(--space-xs);
            border-radius: var(--space-xs);
            margin-top: var(--space-sm);
        }

        &.description_text {
            text-align: left;
            margin: 0 0 var(--space-sm);
        }

        .icon {
            display: inline-block;
            vertical-align: middle;
            padding-bottom: 0.1em;
        }

        &.category {
            text-align: left;
            flex: 0;
        }

        &.name {
            color: var(--color-contrast-high);
            font-weight: bold;
            padding: var(--space-3xs);
            padding-top: 0;

            &.winner_name span {
                text-decoration: underline;
            }
        }

        &.category_count {
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
