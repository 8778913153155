@use "@/scss/config" as *;


.more_specificity {
    .banner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: var(--space-sm);

        .text {
            flex: 1;
            font-size: var(--text-base);
            p {
                margin: 0;
            }
        }
    }
}
