
.loading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: var(--space-sm);
}

.loading_menu {
    width: 100%;
    max-width: 30rem;
    background-color: var(--color-contrast-low);
    background-image: linear-gradient(
                    180deg,
                    var(--color-contrast-low) 0%,
                    var(--color-contrast-between_lower_low) 100%,
    );
    border-bottom: var(--space-3xs) var(--color-contrast-lower) solid;
    border-radius: var(--space-sm);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.5);
    padding: var(--space-md) var(--space-sm);
    text-align: center;
    margin-bottom: var(--space-md);
    pointer-events: auto;

    h1 {
        font-size: var(--text-2xl);
        color: var(--color-contrast-higher);
        margin-top: 0;
    }
    p {
        font-size: var(--text-lg);
        color: var(--color-contrast-high);
        margin-bottom: 0;
    }
    input {
        font-size: var(--text-lg);
        color: var(--color-contrast-above);
        margin-top: var(--space-sm);
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        background-color: var(--color-contrast-low);
    }
}

.copy_link_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 var(--space-sm);

    input {
        flex-grow: 1;
        margin-bottom: var(--space-sm);
    }
}

.loading_spinner {
    margin-top: var(--space-xs);
}

.discord_link {
    p {
        margin-top: 0;
    }
    & > a {
        display: block;
        margin-top: var(--space-sm);
        img {
            width: 100%;
            max-width: 65%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
