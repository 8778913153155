
.card {
    max-width: 24em;
}

.rule, .read_more {
    margin-top: var(--space-xs);
    margin-bottom: 0;
}

.field {
    font-weight: bold;
}

.show_path {
    display: inline-block;
    color: var(--color-primary);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.icon {
    display: inline-block;
    max-width: 1.8em;
    max-height: 1.8em;
    margin-right: 0.4em;
    vertical-align: middle;
}
