
.legend {
    display: flex;
    gap: 2rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    color: var(--color-contrast-high);

    .legend_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--text-sm);
    }

    .legend_title_row {
        display: flex;
        align-items: center;
    }

    .legend_colour {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: var(--space-3xs);
    }

    .legend_game_type_name {
        font-weight: bold;
    }
}
