
.social_container {
    width: 100%;
    padding: var(--space-xs);
    padding-top: var(--space-2xs);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--space-xs);

    a {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: var(--space-xs);
        row-gap: var(--space-xs);
    }

    .social_icon {
        line-height: 0;

        &, img {
            width: 2.6em;
        }
    }

    p {
        margin: var(--space-3xs) 0 0;
    }
}
