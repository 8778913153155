@use "@/scss/config" as *;


.header_wrapper {
    display: flex;
    width: 100%;

    .header {
        display: grid;
        gap: var(--space-sm);
        grid-template-areas:
                "logo accounts"
                "links links";

        .logo {
            grid-area: logo;
        }
        .links {
            grid-area: links;
        }
        .account {
            grid-area: accounts;
        }

        @include min-width(sm) {
            grid-template-columns: auto 1fr auto;
            grid-template-areas: "logo links accounts";
        }

        text-align: left;
        margin-left: auto;
        margin-right: auto;

        box-sizing: border-box;
        width: 100%;
        max-width: 1400px;
        padding: var(--space-sm);
        font-size: var(--text-lg);

        &, a, .logo {
            text-decoration: none;
        }

        .logo {
            display: flex;
            align-items: center;
            opacity: 1;

            img {
                height: 2em;
                width: auto;
            }
        }

        .links {
            display: flex;
            align-items: center;

            @include min-width(sm) {
                padding: var(--space-sm);
            }

            .header_link {
                margin-right: var(--space-md);
                color: var(--color-contrast-higher);
                opacity: 0.8;

                &:hover, &.active_header_link {
                    opacity: 1.0;
                }
            }
        }

        .account {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }

        @include max-width(xs) {
            .hide_on_mobile {
                display: none;
            }
        }
    }
}
