@use "@/scss/config" as *;


.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-3xs);
    cursor: pointer;
    padding: 0.4em;
    font-size: var(--text-md);
    color: var(--color-contrast-high);

    svg {
        fill: var(--color-contrast-high);
        width: 1.25em;
        height: 1.25em;
        max-width: none;
    }

    &:hover {
        color: var(--color-contrast-higher);
        svg {
            fill: var(--color-contrast-higher);
        }
    }

    &:active {
        color: var(--color-contrast-high);
        svg {
            fill: var(--color-contrast-high);
        }
    }
}

.menu {
    &#{&} {
        padding: 0;
        border: 1px solid var(color-bg);
        border-radius: var(--space-sm);
        background-color: var(--color-bg-darker);
        overflow: hidden;
        min-width: 240px;
    }
}