.search {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.searchInput {
    padding: 8px;
    font-size: var(--text-sm);
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.searchResults {
    padding: 0;
}

.resultItem {
    font-size: var(--text-sm);
    margin-bottom: var(--space-xs);
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    button {
        margin: 0 var(--space-xs);

        &:disabled {
            color: var(--color-contrast-medium);
            cursor: not-allowed;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.pagination button:disabled {
    color: var(--color-contrast-medium);
    cursor: not-allowed;
    &:hover {
        text-decoration: none;
    }
}

.pagination span {
    font-size: var(--text-sm);
}
