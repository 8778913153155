
.historical_rating {
    margin: 0 0 var(--space-xs);
    align-items: flex-start;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .graph {
        height: 10rem;
        svg {
            border-radius: var(--space-sm);
            overflow: hidden;
        }
    }
}
