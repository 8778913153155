
.player_name {
    margin: 0;

    .player_name_text {
        color: var(--color-contrast-high);
        font-size: var(--text-lg);
        font-weight: bold;
        margin: 0;

        &.small_name {
            font-size: var(--text-md);
        }
    }

    &:hover {
        text-decoration: none;
    }
}
