
.main {
    width: 100%;
    height: 100%;
    padding: var(--space-sm) 0;

    section {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        padding-bottom: var(--space-lg);

        h2 {
            text-align: center;
            margin-bottom: var(--space-lg);
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: var(--space-sm);
    }
}
