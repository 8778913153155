
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 160px;
    height: 100%;

    div {
        flex-grow: 0;
    }

    p {
        margin-top: auto;
        margin-bottom: 0;
        max-width: 100%;
    }
}
