.container {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.spinner, .bg {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 10%;
    border: 8px solid;
    border-radius: 50%;
}
.spinner {
    border-color: #FFFFFF transparent transparent transparent;
    animation: spin 1.6s cubic-bezier(.4,0,.6,1) infinite;
    @keyframes spin {
        0% {
            transform: rotate(-45deg);
        }
        100% {
            transform: rotate(315deg);
        }
    }
    &.first {
        animation-delay: 0s;
    }
    &.second {
        animation-delay: -0.15s;
    }
    &.third {
        animation-delay: -0.3s;
    }
    &.fourth {
        animation-delay: -0.45s;
    }
}
.bg {
    border-color: var(--color-contrast-lower);
}